<template>
  <v-container fluid>
    <div id="lprs" style="position: relative; min-height: calc(100vh - 38px);">
      <mx-preloader style="margin-top: 14px;" :z-index="7" :logo="false" :spiner="true" :loader-status="preloader === true"/>

<!--      <v-row class="mt-0 mb-6 d-none d-xl-block">-->
<!--        <v-col cols="12" class="d-flex">-->
<!--          <h2 class="d-flex flex-column ml-10">-->
<!--            <strong style="font-size: 22px; margin-top: -3px;">{{ company.company_name }}</strong>-->
<!--            <span style="font-size: 18px; font-weight: 400; margin-top: -4px;">{{ customer.first_name }} {{ customer.last_name }}</span>-->
<!--          </h2>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <v-row v-if="Object.keys(company).length === 0">
        <v-col cols="12" sm="6" class="offset-3">
          <h1 class="text-center mt-16 display-1">{{ $t('company.not_found.phone') }}</h1>
        </v-col>
      </v-row>
      <v-row v-else class="mt-n6">
        <v-col cols="12">
          <v-card flat>

            <v-tabs :ripple="false" v-model="tab" fixed-tabs>
              <v-tabs-slider :ripple="false" color="primary"></v-tabs-slider>
              <v-tab :disabled="item.disabled" :ripple="false" v-for="item in items" :key="item.id">
                {{ item.name }}
              </v-tab>
            </v-tabs>

            <v-tabs-items id="tbsh" v-model="tab" style="height: calc(100vh - 110px);">
              <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
                <v-row style="height: 100%;">
                  <template>
                    <v-col cols="12" md="6">
                      <CompanyCard :company="company"/>
                    </v-col>
                  </template>

                  <v-col style="margin-top: auto;" cols="12">
                    <v-card flat style="min-height: 86px;">
                      <v-card-title>
                        <v-icon class="mr-2">mdi-clipboard-account-outline</v-icon>
                        <span style="font-weight: 300;">{{ customer.first_name }} {{ customer.last_name }}</span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-subtitle>
                        <strong>{{ $t('customer.show.gender.title') }}:</strong>
                        <span>{{ $t(`customer.show.gender.${customer.gender}`) }}</span> <br>
                        <strong>{{ $t('customer.show.identification') }}:</strong> <span>{{customer.identification }}</span> <br>
                        <strong>{{ $t('customer.show.email') }}:</strong> <span><a
                          :href="`mailto:${customer.email}`">{{ customer.email }}</a></span> <br>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
                <v-row style="height: 100%;">
                  <template>
                    <v-col cols="12">
                      <TableFullPersons :contact_persons="contact_persons"/>
                    </v-col>
                  </template>
                </v-row>
              </v-tab-item>

              <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
                <v-row style="height: 100%;">
                  <template>
                    <v-col cols="12">
                      <TableFullAddresses :addresses="company_addresses"/>
                    </v-col>
                  </template>
                </v-row>
              </v-tab-item>

              <v-tab-item class="px-6" style="height: 100%; overflow-y: auto">
                <v-row style="height: 100%;">
                  <template v-if="!preloader">
                    <v-col class="align-center justify-center d-flex flex-column" cols="12">
                      <h2 class="mt-8">
                        {{ titleReplace(company.faq.welcome_text) }}
                      </h2>
                      <v-btn v-if="questions.length === 0" @click="getQuestions" x-large class="mt-16" color="primary">{{ $t('faq.question.get') }}</v-btn>
                    </v-col>
                  </template>
                  <template v-if="questions.length > 0">
                    <v-col cols="12">
                      <QuestionsList :questions="questions"/>
                    </v-col>
                  </template>
                </v-row>
              </v-tab-item>

              <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
                <v-row style="height: 100%;">
                  <template>
                    <v-col cols="12">
                      <TableFullCustomerAccessData :cads="cads" />
                    </v-col>
                  </template>
                </v-row>
              </v-tab-item>

            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>

</template>

<script>

import CompanyCard from "@/components/Company/CompanyCard";
import TableFullPersons from "@/components/Tables/TableFullPersons";
import TableFullAddresses from "@/components/Tables/TableFullAddresses";
import QuestionsList from "@/components/Faq/QuestionsList";
import TableFullCustomerAccessData from "@/components/Tables/TableFullCustomerAccessData";

export default {
  components: {CompanyCard, TableFullPersons, TableFullAddresses, QuestionsList, TableFullCustomerAccessData},
  data() {
    return {
      preloader: true,
      tab: 0,
      items: [
        { id: 1, name: this.$t('tabs.companies'), disabled: false},
        { id: 2, name: this.$t('tabs.employee'), disabled: false},
        { id: 3, name: this.$t('tabs.address'), disabled: false},
        { id: 4, name: this.$t('tabs.faq'), disabled: false},
        { id: 5, name: this.$t('tabs.cad'), disabled: false},
      ],
      company: {},
      customer: {},
      questions: [],
      contact_persons: [],
      company_addresses: [],
      cads: [],
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    titleReplace(text) {
      if (text === null) {
        return text
      }

      let title_replace = text.replace('<Vorname>', this.$store.getters.user.first_name)
      title_replace = title_replace.replace('<Nachname>', this.$store.getters.user.last_name)

      return title_replace
    },
    mQuery(query) {
      if (undefined !== query['did']) {
        let q = query['did'].replace(/\D+/g,"")
        if ( parseInt(q[0]) === 0) {
          query['did'] = q
        } else {
          query['did'] = '00' + q
        }
      }
      query['employee_id'] = this.$store.getters.user.id
      return query
    },
    getCompany() {
      if (this.$route.query.cn === undefined) {
        this.preloader = false
        return
      }

      this.$store.dispatch('companySearchFromCall', this.mQuery(this.$route.query)).then(() => {
        this.company = this.$store.getters.company
        if (Object.keys(this.company).length > 0) {
          this.customer = this.$store.getters.company.customer
          this.contact_persons = this.$store.getters.company.persons
          this.company_addresses = this.$store.getters.company.addresses
          this.cads = this.$store.getters.company.customer_access_data

          if (this.cads.length === 0) {
            this.items[4].disabled = true
          }

          if (this.customer.can_faq === 1 && this.company.override === 0) {
            this.tab = 3
          }

          if (this.customer.can_faq === 0) {
            this.items[3].disabled = true
          }

          if(this.company.override === 1) {
            this.tab = 0
          }
        }

        setTimeout(this.mxLoader, 100)
      })
    },
    getQuestions() {
      this.$store.dispatch('questionPerFaqId', this.company.faq.id).then(() => {
        this.questions = this.$store.getters.company.faq.questions
      })
    },
    mxLoader() {
      this.preloader = false
    }
  }
}
</script>
